import styled, { css } from 'styled-components';

export const FeatureSection = styled.div`
  position: relative;
`;

export const FeatureImg = styled.img`
  width: 100%;
  height: 14rem;
  object-fit: cover;
  @media (min-width: 1200px) {
    height: 43.7rem;
  }
`;

export const FeatureText = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.white};
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 4.4rem;
  margin: 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 3.4rem;
  }
`;

export const Title = styled.h1`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2.2rem;
  color: ${props => props.theme.quoteTxtColor};
  letter-spacing: 0;
  line-height: 5.2rem;
  text-align: center;
  margin: 2rem 1.4rem 0;
  @media (min-width: 1200px) {
    font-family: 'Heuristica';
    font-weight: 400;
    font-size: 4.2rem;
    margin: 2rem 0 0;
    text-align: left; 
  }
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  text-align: ${props => props.align || 'center'};
  line-height: 2.2rem;
  @media (min-width: 1200px) {
    text-align: left; 
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    margin: 0 14rem;
    align-items: center;
  }
  @media (min-width: 1200px) {
    margin: 1.6rem 0;
    display: grid;
    grid-template-columns: 32rem 32rem;
    grid-column-gap: 3rem;
    .terms {
      grid-column: 1 / span 2;
    }
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    display: block;
    margin-bottom: 2.3rem;
    ${props =>
      props.full &&
      css`
        grid-column: 1 / span 2;
        margin-bottom: 0rem;
        textarea {
          width: 100%;
        }
      `};
  }
`;

export const FormLabel = styled.h3`
  width: 33.5rem;
  margin: 1rem 0;
  padding: 0;
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  line-height: 1.4rem;
`;

export const Input = styled.input`
  background-color: ${props => props.theme.bgColor};
  border: 0.2rem solid ${props => props.theme.inputBorder};
  box-sizing: border-box;
  height: 4.2rem;
  padding: 0.2rem 1rem;
  width: 34rem;
  &.error {
    border-color: ${(props) => props.theme.errorTxt};
  }
  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const Textarea = styled.textarea`
  background-color: ${props => props.theme.bgColor};
  border: 0.2rem solid ${props => props.theme.inputBorder};
  box-sizing: border-box;
  min-width: 34rem;
  height: 10rem;
  padding: 1rem;
  &.error {
    border-color: ${(props) => props.theme.errorTxt};
  }
`;

export const ContactField = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  ${Input} {
    width: 19rem;
  }
  @media (min-width: 1200px) {
    grid-column-gap: 0.6rem;
    ${Input} {
      width: 17rem;
    }
  }
`;

export const SelectField = (theme, hasError) => ({
  width: 145,
  height: 42,
  border: `0.2rem solid ${hasError ? theme.errorTxt : theme.inputBorder}`,
  borderRadius: 0,
});

export const ErrorMsg = styled.span`
  color: ${props => props.theme.errorTxt};
  font-size: 1.4rem;
  font-family: 'Avenir';
  font-weight: 400;
`;