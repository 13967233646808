import React, { useContext, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Button, Container, Section } from '@components/styledComponents/index';
import {
  FeatureImg,
  FeatureSection,
  FeatureText,
  Form,
  FormGroup,
  FormLabel,
  Input,
  Text,
  Textarea,
  Title,
  ContactField,
  SelectField,
  ErrorMsg,
} from './styles';
import { ThemeContext } from 'styled-components';
import { onlyNumberKey } from '@utils/helpers';
import countriesCall from '@utils/countryCall.json';

const selectStyle = (theme, hasError) => ({
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? theme.primaryColor : theme.bgColor,
  }),
  control: styles => ({
    ...styles,
    ...SelectField(theme, hasError),
  }),
  singleValue: provided => {
    return { ...provided };
  },
});

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ContactUs = () => {
  const theme = useContext(ThemeContext);
  const [selectedCode, setSelectedCode] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [countryCodeError, setCountryCodeError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [messageError, setMessageError] = useState(null);

  function emptyFields() {
    setSelectedCode(null);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
  }

  function validateForm() {
    let status = true;
    if (!firstName.length) {
      setFirstNameError('This field is required!');
      document.querySelector('[name="firstName"]').focus();
      status = false;
    } else {
      setFirstNameError(null);
    }
    if (!lastName.length) {
      setLastNameError('This field is required!');
      document.querySelector('[name="lastName"]').focus();
      status = false;
    } else {
      setLastNameError(null);
    }
    if (!email.length) {
      setEmailError('This field is required!');
      document.querySelector('[name="email"]').focus();
      status = false;
    } else if (!validateEmail(email)) {
      setEmailError('Please add valid email!');
      document.querySelector('[name="email"]').focus();
      status = false;
    } else {
      setEmailError(null);
    }
    if (!selectedCode || !selectedCode.value) {
      setCountryCodeError(true);
      document.querySelector('[name="phone"]').focus();
    } else {
      setCountryCodeError(null);
    }
    if (!phone.length) {
      setPhoneError('This field is required!');
      document.querySelector('[name="phone"]').focus();
      status = false;
    } else {
      setPhoneError(null);
    }
    if (!message.length) {
      setMessageError('This field is required!');
      document.querySelector('[name="message"]').focus();
      status = false;
    } else {
      setMessageError(null);
    }

    return status;
  }

  function sendMessage(e) {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const formData = new FormData();
    formData.append('your-name', `${firstName} ${lastName}`);
    formData.append('your-email', email);
    formData.append('contact-number', `${selectedCode.value}${phone}`);
    formData.append('your-message', message);

    const axiosConfig = {
      method: 'post',
      url:
        process.env.BACKEND_URL +
        'wp-json/contact-form-7/v1/contact-forms/3955/feedback',
      data: formData,
    };

    axios(axiosConfig)
      .then(res => {
        emptyFields();
        alert(res.data.message);
      })
      .catch(e => {
        console.log('error', e);
      });
    return false;
  }

  return (
    <>
      <FeatureSection>
        <FeatureImg
          alt="Contact us page"
          src={require('@images/contactus-bg.jpg')}
          srcSet={`${require('@images/contactus-bg.jpg')}, ${require('@images/contactus-bg@2x.jpg')} 2x`}
        />
        <FeatureText>Let’s talk about your future. Now.</FeatureText>
      </FeatureSection>
      <Container>
        <Section
          padding={'0 1.4rem'}
          deskMargin={'0 0 0 15rem'}
          deskPadding={'2.7rem 1.4rem 3.5rem 0rem'}
          deskWidth={'44.1rem'}
        >
          <Title>Get in touch</Title>
          <Text>
            Want to know more about how we can help? Have a question? Simply
            fill in the form and someone will get back to you as soon as
            possible.
            <br />
            <br />
            We love to collaborate with curious and smart. Let’s do great things
            together!
          </Text>
        </Section>
        <Section deskWidth={'auto'}>
          <form>
            <Form>
              <FormGroup>
                <FormLabel>First Name *</FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={firstName}
                  className={firstNameError ? 'error' : ''}
                  onChange={e => setFirstName(e.target.value)}
                />
                {!!firstNameError && <ErrorMsg>{firstNameError}</ErrorMsg>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Last Name *</FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={lastName}
                  className={lastNameError ? 'error' : ''}
                  onChange={e => setLastName(e.target.value)}
                />
                {!!lastNameError && <ErrorMsg>{lastNameError}</ErrorMsg>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Email Address *</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  className={emailError ? 'error' : ''}
                  onChange={e => setEmail(e.target.value)}
                />
                {!!emailError && <ErrorMsg>{emailError}</ErrorMsg>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Contact number *</FormLabel>
                <ContactField>
                  <Select
                    className="error"
                    isSearchable={true}
                    options={countriesCall}
                    styles={selectStyle(theme, countryCodeError)}
                    placeholder="Country Code (+0)"
                    formatOptionLabel={item => `${item.label}  (${item.value})`}
                    value={selectedCode}
                    onChange={selectedOption => setSelectedCode(selectedOption)}
                  />
                  <Input
                    type="phone"
                    name="phone"
                    value={phone}
                    className={phoneError ? 'error' : ''}
                    onKeyPress={onlyNumberKey}
                    onChange={e => setPhone(e.target.value)}
                  />
                </ContactField>
                {!!phoneError && <ErrorMsg>{phoneError}</ErrorMsg>}
              </FormGroup>
              <FormGroup full={true}>
                <FormLabel>Message *</FormLabel>
                {!!messageError && <ErrorMsg>{messageError}</ErrorMsg>}
                <Textarea
                  name="message"
                  value={message}
                  className={messageError ? 'error' : ''}
                  onChange={e => setMessage(e.target.value)}
                />
              </FormGroup>
              <Text align="left" className="terms">
                By clicking submit, I hereby consent that Wareef sends me via
                email marketing related information with regard to products and
                services of Wareef. I understand that, in order to opt-out from
                receiving such emails in the future, I can use the opt-out
                mechanism that is described in each such email.
                <b> Privacy Policy</b>
                <br />
                <br />
              </Text>
              <Button
                deskWidth={'21.3rem'}
                tabletWidth={'21.3rem'}
                onClick={sendMessage}
              >
                Submit
              </Button>
            </Form>
          </form>
        </Section>
      </Container>
    </>
  );
};

export default ContactUs;
