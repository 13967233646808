import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import ContactUs from '@components/pages/contactus/index';

const ContactUsPage = props => {
  return (
    <Layout {...props}>
      <SEO title="Contact Us" />
      <ContactUs />
    </Layout>
  );
};

export default ContactUsPage;
